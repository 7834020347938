import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

const locale = i18next.language

export default class TimeAgo extends React.PureComponent {
  static getString (date, options) {
    const now = new Date()
    const timeago = date || now

    const ms = now.getTime() - timeago.getTime()

    if (ms < 2 * 60 * 1000) {
      return i18next.t('justnow', { defaultValue: 'Just now', ns: 'time' })
    } else if (ms < 60 * 60 * 1000) {
      const minutes = Math.floor(ms / 60000)
      return i18next.t('minutesago', { defaultValue: '{{count}} minute ago', defaultValue_plural: '{{count}} minutes ago', count: minutes, ns: 'time' })
    } else if (ms < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(ms / 3600000)
      return i18next.t('hoursago', { defaultValue: '{{count}} hour ago', defaultValue_plural: '{{count}} hours ago', count: hours, ns: 'time' })
    } else if (ms < 14 * 24 * 60 * 60 * 1000) {
      const today = new Date(new Date(now).setHours(0, 0, 0, 0))
      const dayago = new Date(new Date(timeago).setHours(0, 0, 0, 0))
      const days = Math.ceil((today.getTime() - dayago.getTime()) / (24 * 60 * 60 * 1000))
      return i18next.t('daysago', { defaultValue: '{{count}} day ago', defaultValue_plural: '{{count}} days ago', count: days, ns: 'time' })
    } else {
      return timeago.toLocaleDateString(locale, Object.assign({ day: 'numeric', month: 'short', year: ms < 365 * 24 * 60 * 60 * 1000 ? undefined : 'numeric' }, options))
    }
  }

  render () {
    const str = TimeAgo.getString(this.props.date, this.props.options)
    return <span {...this.props} title={(this.props.title ? this.props.title + ' ' : '') + (this.props.date || new Date()).toLocaleString(locale, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' })}>{str}</span>
  }
}

TimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  options: PropTypes.object
}
